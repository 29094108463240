import React, {useEffect, useState} from 'react';
import PageSection from "../../../components/PageSection";
import {Col, Row} from "react-bootstrap";
import FormGroup from "../../../components/FormGroup";
import Input from "../../../components/forms/FormEl";
import {Controller, useForm} from "react-hook-form";
import SelectSalas from "../../../Selects/SelectSalas";
import TailscaleDeviceSelect from "../../../Selects/TailscaleDeviceSelect";
import SwitchToggle from "../../../components/Layaout/SwitchToggle";

const ServerForm = ({value, onSubmit, setAction}) => {

  const {register, handleSubmit,setValue, watch, reset, control} = useForm();
  const [tenant,setTenant] = useState('')

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  return (
    <>
      <PageSection>
        <Row>
          <Col>
            <FormGroup>
              <label style={{fontWeight: 'bold'}}>Sala</label>
              <Controller
                name="salaId"
                control={control}
                render={({field}) => <SelectSalas {...field} setTenant={setTenant} tag={true} />}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label>Activo</label><br/>
              <SwitchToggle
                label={'Si'}
                {...register("active")}/>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label style={{fontWeight: 'bold'}}>Nombre</label>
              <Input type={'text'} {...register('name')}/>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label style={{fontWeight: 'bold'}}>Pangolin IP</label>
              <Input type={'text'} {...register('serverIp')}/>
              </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label style={{fontWeight: 'bold'}}>Public IP</label>
              <Input type={'text'} {...register('publicIp')}/>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label style={{fontWeight: 'bold'}}>Token</label>
              <Input type={'text'} {...register('token')}/>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label style={{fontWeight: 'bold'}}>Tailscale device</label>
              <TailscaleDeviceSelect {...register('vpnId')}/>
            </FormGroup>
          </Col>
        </Row>
      </PageSection>
    </>
  );
};

export default ServerForm;
