import React, {useCallback, useEffect, useMemo} from 'react';
import useFetch from "../../hooks/useFetch";
import {Apis} from "../../util/Apis";
import {Button, FlexContainer, Table} from "@wargostec/react-components";
import {DateTime} from "luxon";
import axios from "axios";
import {errorToast, successToast} from "../../components/Layaout/ToastStyles";
import useEditModal from "../../hooks/useEditModal";
import GenericModal2 from "../../hooks/GenericModal2";
import PageContainerFull from "../../components/PageContainerFull";
import {Col, Row} from "react-bootstrap";
import {MdDeleteForever, MdModeEdit} from "react-icons/md";
import {ContainerLoading} from "../SalasList";
import {Loading} from "../../components/forms/Loading";
import PageSection from "../../components/PageSection";
import PageTitle from "../../components/Layaout/PageTitle";
import InvoiceBillNew from "./invoice-bills/InvoiceBillNew";
import InvoiceBillEdit from "./invoice-bills/InvoiceBillEdit";
import InvoiceAlegra from "./invoice-bills/InvoiceAlegra";
import {FaFileInvoiceDollar} from "react-icons/fa";

const InvoiceBillList = () => {

  const {loading, data, fetchData} = useFetch(Apis.BILLING + '/invoice-bills', [], {});

  const {wrapper, refresh, addAction, editAction} = useEditModal({
    addComponent: <InvoiceBillNew/>,
    editComponent: <InvoiceBillEdit/>
  })

  const {wrapper: wrapper2, refresh: refresh2, editAction: createInvoiceBills} = useEditModal({
    editComponent: <InvoiceAlegra/>
  })

  const deleteInvoice = (id) => {
    return axios.delete(Apis.BILLING + '/invoice-bills/' + id).then(() => {
      fetchData()
      successToast('Factura eliminada exitosamente')
    }).catch((err) => {
      errorToast(err ? err.response.data.message : 'Hubo un error al eliminar la factura', 2000)
    })
  }

  const actionValue = useCallback(props => {
    return (
      <>
        <FlexContainer gap='8px' justifyContent={'center'}>
          <Row>
            <>
              <Col>
                <Button icon={<MdModeEdit/>} size={'xs'} variant={'success'}
                        onClick={() => editAction(props.renderValue())}/>
              </Col>
              <Col>
                <Button icon={<FaFileInvoiceDollar/>} size={'xs'} variant={'warning'}
                        onClick={() => createInvoiceBills(props.renderValue())}/>
              </Col>
              <Col>
                <Button size={'xs'} variant={'danger'} icon={<MdDeleteForever/>}
                        onClick={() => deleteInvoice(props.renderValue())}/>
              </Col>
            </>
          </Row>
        </FlexContainer>
      </>
    );
  }, []);

  const createdValue = useCallback(props => <>
      {
        <div>
          {props.renderValue() ?
            <span>{DateTime.fromISO(props.renderValue()).toFormat("yyyy'-'LL'-'dd' 'HH':'mm' ")}</span> :
            <span>---</span>}
        </div>
      }
    </>
    , []);

  useEffect(() => {
    fetchData()
  }, [refresh, refresh2])

  const columns = useMemo(
    () => [
      {
        header: 'N° Factura',
        accessorKey: 'numberTemplate',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
      },
      {
        header: 'Razón social',
        accessorKey: 'companyName',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
      },
      {
        header: 'Fecha',
        accessorKey: 'date',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
      },
      {
        header: 'RUC',
        accessorKey: 'ruc',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
      },
      {
        header: 'Monto',
        accessorKey: 'amount',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
      },
      {
        header: 'Moneda',
        accessorKey: 'currency',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
        cell: (props) => {
          return (
            <div>
              {props.renderValue() === 'PEN' ? 'Soles' : 'Dólares'}
            </div>
          );
        }
      },
      {
        header: 'Fecha de creación',
        accessorKey: 'createdAt',
        cell: createdValue,
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
      },
      {
        header: 'Acciones',
        accessorKey: '_id',
        cell: actionValue,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
    ]
    , []
  )

  return (
    <PageContainerFull>
      <PageSection>
        <PageTitle title={'Facturas WGT'}/>
      </PageSection>

      <PageSection>
        {
          loading ?
            <ContainerLoading>
              <Loading variant='secondary'/>
            </ContainerLoading>
            :
            <Table
              searchable
              columns={columns}
              data={data}
              pageSize={100}
              sortArray={[{id: 'createdAt', desc: true}]}
              onAdd={addAction}
            />
        }
      </PageSection>

      <GenericModal2 {...wrapper}/>
      <GenericModal2 {...wrapper2}/>
    </PageContainerFull>
  );
};

export default InvoiceBillList;
