import React, {useCallback, useState} from 'react';
import styled from "styled-components";
import {useWauth} from "../../auth/WauthProvider";
import * as BiIcons from "react-icons/bi"
import logoWargos from "../../assets/logo.png"
import {
  Avatar,
  Layout as WargosLayout,
  Menu,
  MenuList,
  SidebarBody,
  SidebarFooter,
  SidebarItem,
  Tooltip
} from '@wargostec/react-components';
import {Outlet, useNavigate} from "react-router-dom";
import {MdSecurity, MdSupportAgent} from "react-icons/md";
import {FaFileInvoiceDollar, FaUsers} from "react-icons/fa";
import MenuItem from "./MenuItem";
import axios from "axios";
import {HiOutlineSlotMachine} from "../../assets/icons/slot-machine";
import {BsDeviceSsdFill} from "react-icons/bs";
import {GrConfigure} from "react-icons/gr";

axios.interceptors.request.use(function (config) {
  const token = JSON.parse(localStorage.getItem("currentUser")).token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response) {
    if (401 === error.response.status) {
      window.location = '/logout';
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  } else {
    return Promise.reject(error);
  }
});

const MenuProfile = styled.div`
  position: fixed;
  top: 60px;
  right: 10px;
  background-color: white;
  box-sizing: border-box;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  padding: 14px;
  border-radius: 6px;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  width: 180px;

  display: ${(props) => (props.showProfile ? 'flex' : 'none')};

  & > span:first-child {
    font-size: 14px;
    font-weight: bold;
    color: #555;
    max-width: 100%;
    overflow: hidden;
    text-align: center;
  }

  & > span:last-child {
    font-size: 12px;
    font-weight: 500;
    color: #555;
    cursor: pointer;
    user-select: none;
  }

  & > div {
    height: 1px;
    width: 100%;
    background-color: #e9ecef;
  }
`;

const Layout = () => {
  const navigate = useNavigate();

  const [showProfile, setShowProfile] = useState(false);
  const {isAuthenticated, loginWithRedirect} = useWauth();

  const nameString = localStorage.getItem('name')
  const name = nameString?.replace(/['"]+/g, '');

  const logout = () => {
    localStorage.clear();
    navigate('/logout');
  };

  const Items = () => {
    return (
      <>
        <SidebarBody>
          <Tooltip text="CONFIG">
            <SidebarItem active={true} icon={<GrConfigure size={20}/>}/>
          </Tooltip>
        </SidebarBody>
        <SidebarFooter>
          <SidebarItem
            icon={
              <MdSecurity
                size={20}
                onClick={() =>
                  window.open(
                    'https://admin.wargos.cloud',
                    '_blank',
                    'noreferrer'
                  )
                }
              />
            }
          />
        </SidebarFooter>
      </>
    );
  };

  const Menus = useCallback(() => {
    return (
      <MenuList>
        <Menu title='Admin' icon={<FaUsers size={20}/>}>
          <MenuItem title='Salas' path='salas-list'/>
          <MenuItem title='Tenant' path='tenants-list'/>
          <MenuItem title='Banner' path='banner-list'/>
          <MenuItem title='Usuarios WGT' path='users'/>
          <MenuItem title='Permisos' path='permissions'/>
          <MenuItem title='Clientes' path='clients'/>
          <MenuItem title='Ludopatas' path='ludopatas'/>
        </Menu>
        <Menu title='Soporte' icon={<MdSupportAgent size={20}/>}>
          <MenuItem title='Chats' path='conversation-list'/>
          <MenuItem title='Tickets' path='ticket-list'/>
          <MenuItem title='Instalaciones' path='installations-list'/>
        </Menu>
        <Menu title='Facturación' icon={<FaFileInvoiceDollar size={20}/>}>
          <MenuItem title='Facturas' path='invoice-list'/>
          <MenuItem title='Configuracion' path='billing'/>
          <MenuItem title='Facturas WGT' path='invoice-bill-list'/>
        </Menu>
        <Menu title='Máquinas' icon={<HiOutlineSlotMachine size={20}/>}>
          <MenuItem title='Fabricante' path='fabricante'/>
          <MenuItem title='Modelo' path='modelo'/>
          <MenuItem title='Modelos por sala' path='model-by-sala'/>
          <MenuItem title='Crear ticket' path='create-ticket'/>
        </Menu>
        <Menu title='Servidor' icon={<BsDeviceSsdFill size={20}/>}>
          <MenuItem title='Servicios' path='channel-list'/>
          <MenuItem title='Servidores' path='server-list'/>
          <MenuItem title='Dispositivos' path='devices'/>
          <MenuItem title='Dispositivos por sala' path='devices-grouped'/>
          <MenuItem title='Firmware' path='firmware'/>
          <MenuItem title='Tramas' path='tramas'/>
          <MenuItem title='Asignacion serie' path='programming'/>
          <MenuItem title='Lotes' path='pangolin-lote-list'/>
        </Menu>
      </MenuList>
    );
  }, []);

  const Header = () => {
    return (
      <>
        <Avatar
          name={name}
          onClick={() => setShowProfile((previousState) => !previousState)}
        />
        <MenuProfile showProfile={showProfile}>
          <span>{name}</span>
          <div></div>
          <span onClick={logout}>
						<BiIcons.BiLogOut/> Cerrar Sesión
					</span>
        </MenuProfile>
      </>
    );
  };

  if (!isAuthenticated) {
    loginWithRedirect();
    return;
  }

  return (
    <>
      <WargosLayout
        brand='INTERNAL'
        logo={logoWargos}
        items={<Items/>}
        menus={<Menus/>}
        header={<Header/>}
        defaultOpen
      >
        <Outlet/>
      </WargosLayout>

    </>
  );
};

export default Layout;
