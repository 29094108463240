import React, {useEffect} from 'react';
import axios from "axios";
import {Apis} from "../../../util/Apis";
import InvoiceBillForm from "./InvoiceBillForm";

const InvoiceBillNew = ({setAction, setTitle}) => {

  const create = async (params) => {
    return await axios.post(Apis.BILLING + '/invoice-bills', params).catch((err) => {
    });
  }

  useEffect(() => {
    setTitle("Nueva factura");
  }, []);

  return (
    <>
      <InvoiceBillForm value={{}} onSubmit={create} setAction={setAction}/>
    </>
  );
};

export default InvoiceBillNew;
