import React, {useEffect} from 'react';
import {Apis} from "../../util/Apis";
import axios from "axios";
import {Controller, useForm} from "react-hook-form";
import PageSection from "../../components/PageSection";
import {Col, Row} from "react-bootstrap";
import FormGroup from "../../components/FormGroup";
import SelectSalas from "../../Selects/SelectSalas";
import {Input} from "@wargostec/react-components";
import useFetch from "../../hooks/useFetch";
import {TextArea} from "../../components/forms/FormEl";

const ConversationMatch = ({id, setAction, setTitle}) => {

  const {data} = useFetch(Apis.SUPPORT + "/conversation/by-intercom-id/" + id, []);

  function update(params) {
    return axios.post(Apis.SUPPORT + '/conversation/link-sala/' + id, params);
  }

  useEffect(() => {
    setTitle('Adjuntar sala')
  }, []);

  const {handleSubmit, control, reset, register} = useForm();

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      reset(data)
    }
  }, [data])

  useEffect(() => {
    setAction(() => handleSubmit(update))
  }, [])

  return (
    <div>
      <div>
        <PageSection>
          <Row>
            <Col xs={12}>
              <FormGroup>
                <label>Id</label>
                <Input type="number" value={id} disabled={true}/>
              </FormGroup>
            </Col>
            <Col xs={12}>
              <FormGroup>
                <label>Autor</label>
                <Input {...register('author.name')} disabled={true}/>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label>Sala</label>
                <Controller
                  name="salaId"
                  control={control}
                  render={({field}) => <SelectSalas {...field} />}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <FormGroup>
                <label>Titulo (IA)</label>
                <Input key={id} {...register('summaryAi.title')} disabled={true}/>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label>Resumen (IA)</label>
                <TextArea key={id} {...register('summaryAi.summary')} disabled={true}/>
              </FormGroup>
            </Col>
          </Row>
        </PageSection>
      </div>
    </div>
  );
};

export default ConversationMatch;
