import React, {useEffect} from 'react';
import {useForm} from "react-hook-form";
import FormGroup from "../../../components/FormGroup";
import {Input} from "@wargostec/react-components";
import {SelectV2} from "../../../components/forms/FormEl";

const InvoiceBillForm = ({value, onSubmit, setAction}) => {

  const {register, handleSubmit, watch, reset, control} = useForm();

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  return (
    <>
      <React.Fragment>
        <FormGroup>
          <label>N° Factura</label>
          <Input {...register("numberTemplate")} />
        </FormGroup>
        <FormGroup>
          <label>Razón Social</label>
          <Input {...register("companyName")} />
        </FormGroup>
        <FormGroup>
          <label>RUC</label>
          <Input {...register("ruc")} />
        </FormGroup>
        <FormGroup>
          <label>Monto</label>
          <Input {...register("amount")} />
        </FormGroup>
        <FormGroup>
          <label>Moneda</label>
          <SelectV2 {...register("curency")} options={[
            {id: "PEN", name: "Soles"},
            {id: "USD", name: "Dólares"}
          ]}>
          </SelectV2>
        </FormGroup>
        <FormGroup>
          <label>Fecha</label>
          <Input type="date" {...register("date")} />
        </FormGroup>
      </React.Fragment>

    </>
  );
};

export default InvoiceBillForm;
