import React, {useEffect} from 'react';
import useFetch from "../../../hooks/useFetch";
import {Apis} from "../../../util/Apis";
import axios from "axios";
import InvoiceBillForm from "./InvoiceBillForm";

const InvoiceBillEdit = ({id, setAction, setTitle}) => {

  const {loading, data} = useFetch(Apis.BILLING + '/invoice-bills/' + id);

  function update(params) {
    return axios.put(Apis.BILLING + '/invoice-bills/' + id, params);
  }

  useEffect(() => {
    setTitle('Editar factura');
  }, []);

  return (
    <div>
      {!loading && data &&
        <InvoiceBillForm value={data} onSubmit={update} setAction={setAction}/>
      }
    </div>
  );
};

export default InvoiceBillEdit;
