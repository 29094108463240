import React, {useEffect} from 'react';
import useFetch from "../hooks/useFetch";
import {Apis} from "../util/Apis";
import axios from "axios";
import {errorToast, successToast} from "../components/Layaout/ToastStyles";
import {useNavigate, useParams} from "react-router-dom";
import PageContainer from "../components/PageContainer";
import PageSection from "../components/PageSection";
import PageTitle from "../components/Layaout/PageTitle";
import {useForm} from "react-hook-form";
import ModelForm from "./ModelForm";
import {ContainerLoading} from "./SalasList";
import {Preview} from "../components/forms/Preview";
import {Loading} from "../components/forms/Loading";

const ModelEdit = () => {

  const {id} = useParams()

  const {loading, data, fetchData} = useFetch(Apis.SALAS + '/slot-models/by-model-id/' + id);
  const {register, reset, getValues, formState: {errors}} = useForm();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1)
  }

  const update = async (params) => {
    params.comPortsNumber = parseInt(params.comPortsNumber)
    params.price = parseInt(params.price)
    params.manufacturerId = parseInt(params.manufacturerId)
    params.modelId = parseInt(params.modelId)
    params.machineTypeId = parseInt(params.machineTypeId)
    params.connectorId = parseInt(params.connectorId)

    await axios.put(Apis.SALAS + '/slot-models/by-model-id/' + id, params)
      .then(res => {
        successToast()
        navigate(-1)
      }).catch(err => {
        errorToast()
      })
  }

  useEffect(() => {
    if (data) {
      reset(data)
    }
  }, [data])

  return (
    <PageContainer>
      <PageSection>
        <Preview text='Regresar' onClick={() => navigate(-1)}/>
        <PageTitle title={'Edit modelo'}/>

        <div>
          {
            loading ?
              <ContainerLoading>
                <Loading variant='secondary'/>
              </ContainerLoading>
              :
              <>
                <ModelForm value={data && data} onSubmit={update} mode={id} fetchData={fetchData}/>
              </>
          }
        </div>
      </PageSection>
    </PageContainer>

  );
};

export default ModelEdit;
