import React, {useCallback, useEffect, useMemo, useState} from 'react';
import useFetch from "../../../hooks/useFetch";
import {Apis} from "../../../util/Apis";
import axios from "axios";
import useEditModal from "../../../hooks/useEditModal";
import StageEdit from "./stage/StageEdit";
import StageNew from "./stage/StageNew";
import GenericModal2 from "../../../hooks/GenericModal2";
import PageTitle from "../../../components/Layaout/PageTitle";
import {Button, FlexContainer, Table} from "@wargostec/react-components";
import PageSection from "../../../components/PageSection";
import {DateTime} from "luxon";
import {Modalverification} from "../../../components/forms/ModalVerification";
import {Col, Row} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import SelectSalas from "../../../Selects/SelectSalas";
import {TfiReload} from "react-icons/tfi";
import {GrClearOption} from "react-icons/gr";
import {useSearchParams} from "react-router-dom";
import {ContainerLoading} from "../../SalasList";
import {Loading} from "../../../components/forms/Loading";
import {SwitchToggle} from "../../../components/forms/SwitchToggle";
import {errorToast, successToast} from "../../../components/Layaout/ToastStyles";
import PageContainer from "../../../components/PageContainer";
import PageContainerFull from "../../../components/PageContainerFull";

const InstallationsList = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const salaIdParam = searchParams.get('salaId') || '';
  const [params, setParams] = useState({salaId: salaIdParam});
  const {loading, data, fetchData} = useFetch(Apis.SALAS + '/stage', [], params, {}, false);
  const {control} = useForm({defaultValues: {salaId: salaIdParam}});

  const deleteElement = (id) => {
    axios.delete(Apis.SALAS + '/stage/' + id)
        .then(res => {
          fetchData()
        })
        .catch(error => {
        })
  }

  const completeStage = (id) => {
    axios.delete(Apis.SALAS + '/stage/' + id)
        .then(res => {
          fetchData()
        })
        .catch(error => {
        })
  }

  const {wrapper: wrapper1, refresh: refresh1, addAction, editAction} = useEditModal({
    editComponent: <StageEdit/>,
    addComponent: <StageNew/>
  })

  const getData = (params) => {
    setParams(params)
    setSearchParams(params)
  }

  useEffect(() => {
    if (params.salaId) {
      fetchData()
    }
  }, [refresh1])

  useEffect(() => {
    if (params) {
      if (params.salaId) {
        fetchData()
      }
    }
  }, [params])

  const actionValue = useCallback((props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isDelete, setIsDelete] = useState(false);

    return (
        <>
          <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
            <Button text={'Editar'} size={'xs'} variant={'success'} onClick={() => editAction(props.renderValue())}/>
            <Button text={'Eliminar'} size={'xs'} variant={'danger'} onClick={() => setIsDelete(true)}/>
            <Modalverification
                show={isDelete}
                handleClose={() => setIsDelete(false)}
                title='¿Seguro que desea eliminar este ludópata?'
                handleDelete={() => deleteElement(props.renderValue())}
            />
          </div>
        </>
    );
  }, []);

  const createdValue = useCallback(props => <>
        {
          <div>
            {props.renderValue() ?
                <span>{DateTime.fromISO(props.renderValue()).toFormat("yyyy'-'LL'-'dd' 'HH':'mm' ")}</span> :
                <span>---</span>}
          </div>
        }
      </>
      , []);

  const setComplete = (id, value) => {
    const body = {
      complete: value
    }
    return axios.post(`${Apis.SALAS}/stage/${id}/completed`, body)
      .then(res => {
        successToast(value ? 'Etapa completada' : 'Etapa incompleta');
        return true;
      })
      .catch(error => {
        errorToast('Error al completar la etapa');
        return false;
      });
  };

  const CompleteCell = ({ row }) => {
    const [isComplete, setIsComplete] = useState(row.original.complete);

    const handleChange = async () => {
      const newValue = !isComplete;
      const success = await setComplete(row.original.id, newValue);

      if (success) {
        setIsComplete(newValue);
      }
    };

    return (
      <FlexContainer justifyContent={'center'}>
        <SwitchToggle checked={isComplete} onChange={handleChange} />
      </FlexContainer>
    );
  };

  const columns = useMemo(
      () => [
        {
          header: 'Titulo',
          accessorKey: 'title',
          meta: {
            headerClassName: 'text-left',
            columnClassName: 'text-left',
          },
        },
        {
          header: 'Fecha estimada',
          accessorKey: 'estimateDate',
          cell: createdValue,
          meta: {
            headerClassName: 'text-left',
            columnClassName: 'text-left',
          },
        },
        {
          header: 'Fecha real',
          accessorKey: 'realDate',
          cell: createdValue,
          meta: {
            headerClassName: 'text-left',
            columnClassName: 'text-left',
          },
        },
        {
          header: 'Duracion',
          accessorKey: 'duration',
          meta: {
            headerClassName: 'text-left',
            columnClassName: 'text-left',
          },
        },
        {
          header: 'Comentario',
          accessorKey: 'comment',
          meta: {
            headerClassName: 'text-left',
            columnClassName: 'text-left',
          },
        },
        {
          header: 'Fecha de creación',
          accessorKey: 'createdAt',
          cell: createdValue,
          meta: {
            headerClassName: 'text-left',
            columnClassName: 'text-left',
          },
        },
        {
          header: 'Fecha de actualización',
          accessorKey: 'updateAt',
          cell: createdValue,
          meta: {
            headerClassName: 'text-left',
            columnClassName: 'text-left',
          },
        },
        {
          header: 'Completada',
          accessorKey: 'complete',
          cell: ({ row }) => <CompleteCell row={row} />,
          meta: {
            headerClassName: 'text-center',
            columnClassName: 'text-center',
          },
        },
        {
          header: 'Acciones',
          accessorKey: 'id',
          cell: actionValue,
          meta: {
            headerClassName: 'text-center',
            columnClassName: 'text-center',
          },
        },
      ]
      , []
  )

  const reloadData = () => {
    if (params.salaId) {
      fetchData()
    }
  }

  const clear = () => {
    getData({})
  }

  return (
      <PageContainerFull>
        <PageSection>
          <PageTitle title={'Instalaciones'}/>
          <FlexContainer column gap='20px'>
            <Row>
              <Col>
                <div style={{width: '300px'}}>
                  <Controller
                      name="salaId"
                      control={control}
                      render={({field}) => (
                          <SelectSalas
                              {...field}
                              onChange={(value) => {
                                field.onChange(value);
                                getData({salaId: value});
                              }}
                          />
                      )}
                  />
                </div>
              </Col>
              <Col>
                <Button style={{paddingRight: "10px"}} variant={'success'} onClick={reloadData}
                        icon={<TfiReload/>}/>
              </Col>
              <Col>
                <Button variant={'primary'} onClick={clear} icon={<GrClearOption/>}/>
              </Col>
              <Col>
                <Button text={'Nuevo'} variant={'primary'} onClick={addAction}/>
              </Col>
            </Row>

          </FlexContainer>

        </PageSection>

        <PageSection>
          {
            loading ?
                <ContainerLoading>
                  <Loading variant='secondary'/>
                </ContainerLoading>
                :
                <Table columns={columns} data={data} emptyListText={'No hay resultados, seleccione una sala'}/>
          }
        </PageSection>

        <GenericModal2 {...wrapper1}/>
      </PageContainerFull>
  );
};

export default InstallationsList;
