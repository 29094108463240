import React, {useEffect} from 'react';
import axios from "axios";
import {Apis} from "../../../util/Apis";
import {useForm} from "react-hook-form";

const InvoiceAlegra = ({id, setAction, setTitle}) => {

  const {handleSubmit} = useForm();

  function createInvoiceBill(params) {
    const data = {
      id: id
    }
    return axios.post(Apis.BILLING + '/invoice-bills/alegra', data);
  }

  useEffect(() => {
    setAction(() => handleSubmit(createInvoiceBill))
  }, [])

  useEffect(() => {
    setTitle("Confirmación de factura");
  }, []);

  return (
    <>
      <span>¿Estás seguro de generar la factura?</span>
    </>
  );
};

export default InvoiceAlegra;
