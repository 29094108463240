import React, {useEffect} from 'react';
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import StageForm from "./StageForm";

const StageNew = ({setAction, setTitle}) => {

  const create = async (params) => {
    params.duration = parseInt(params.duration);
    await axios.post(Apis.SALAS + '/stage', params,{headers: {"X-Sala-ID": params.salaId}}).catch((err) => {
    });
  }

  useEffect(() => {
    setTitle("Nueva etapa");
  }, []);

  return (
      <>
        <StageForm value={{}} onSubmit={create} setAction={setAction}/>
      </>
  );
};

export default StageNew;