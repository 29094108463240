import React, {useEffect} from 'react';
import useFetch from "../../../../hooks/useFetch";
import {Apis} from "../../../../util/Apis";
import axios from "axios";
import StageForm from "./StageForm";

const StageEdit = ({id, setAction, setTitle}) => {

  const {loading, data} = useFetch(Apis.SALAS + '/stage/' + id);

  function update(params) {
    params.duration = parseInt(params.duration)
    return axios.put(Apis.SALAS + '/stage/' + id, params);
  }

  useEffect(() => {
    setTitle('Editar etapa');
  }, []);

  return (
      <div>
        {!loading && data &&
            <StageForm value={data} onSubmit={update} setAction={setAction}/>
        }
      </div>
  );
};

export default StageEdit;