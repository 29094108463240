import React, {useCallback, useEffect, useState} from 'react';
import useFetch from "../hooks/useFetch";
import {Apis} from "../util/Apis";
import {Button, Tab, Table, Tabs} from "@wargostec/react-components";
import {Slidebar} from "../components/forms/Slidebar";
import {Col, FormGroup, Row} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import PageSection from "../components/PageSection";
import PageTitle from "../components/Layaout/PageTitle";
import {ContainerLoading} from "./SalasList";
import {Loading} from "../components/forms/Loading";
import SelectSalas from "../Selects/SelectSalas";
import {FaCheckCircle} from "react-icons/fa";
import {TiDeleteOutline} from "react-icons/ti";
import {useNavigate, useSearchParams} from "react-router-dom";
import {FlexContainer} from "@wargostec/react-components/dist/components";
import {TfiReload} from "react-icons/tfi";

const ModelBySala = () => {

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialParams = Object.fromEntries(searchParams.entries());
  const [params, setParams] = useState(initialParams);
  const {loading, data, fetchData} = useFetch(Apis.TGM + '/machines/model-by-sala', [], params, {}, false);
  const {
    loading: loading2,
    data: data2,
    fetchData: fetchData2
  } = useFetch(Apis.TGM + '/machines/connectors-by-sala', [], params, {}, false);

  const [slideOpen, setSlideOpen] = useState(false)
  const {register, handleSubmit, setValue, reset, control} = useForm({defaultValues: initialParams});

  const getData = (params) => {
    setParams(params)
    setSearchParams(params)
  }

  const reloadData = () => {
    if (params.salaId) {
      fetchData()
      fetchData2()
    }
  }

  useEffect(() => {
    fetchData()
    fetchData2()
  }, [params])

  useEffect(() => {
    reset(params);
  }, [params, reset]);

  useEffect(() => {
    if (initialParams.salaId) {
      setValue('salaId', initialParams.salaId);
    }
  }, [initialParams, setValue]);

  const connectorValue = props => (
    <>
      {props.renderValue() !== 0 ? (
        <div style={{textAlign: "center"}}>
          <FaCheckCircle color='green'/>
        </div>
      ) : (
        <div style={{textAlign: "center"}}>
          <TiDeleteOutline color='black'/>
        </div>
      )}
    </>
  );

  function goEditModel(modelId) {
    navigate("/model-edit/" + modelId);
  }

  const actionValue = useCallback(props => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return (
      <>
        <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
          <Button text={'Modelo'} size={'xs'} variant={'warning'} onClick={() => goEditModel(props.row.original.groupId)}/>
        </div>
      </>
    );
  }, []);

  const columns = [
    {header: 'SalaId', accessorKey: 'salaId'},
    {header: 'Nombre', accessorKey: 'groupName'},
    {
      header: 'Cantidad', accessorKey: 'quantity',
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
    },
    {
      header: 'Conector', accessorKey: 'connectorId',
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
      cell: connectorValue,
    },
    {
      header: 'Acciones', accessorKey: 'groupId',
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
      cell: actionValue,
    },
  ];

  const columnsConnectors = [
    {header: 'Nombre', accessorKey: 'connectorName'},
    {
      header: 'Descripción',
      accessorKey: 'description',
      cell: (props) => (props.row.original.description ? props.row.original.description : '---')
    },
    {header: 'Cantidad', accessorKey: 'quantity'},
  ];

  return (
    <>
      <Slidebar title={'Filtrar'} visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{padding: '16px'}}>
          <FormGroup>
            <label>Sala</label>
            <Controller
              name="salaId"
              control={control}
              render={({field}) => <SelectSalas {...field} />}
            />
          </FormGroup>
          <div style={{paddingTop: '10px'}}>
            <Button variant={'primary'} text='Buscar' onClick={handleSubmit(getData)}/>
          </div>
        </div>
      </Slidebar>

      <PageSection>
        <PageTitle title={'Modelos por sala'}/>
        <FlexContainer column gap='20px'>
          <Row>
            <Col>
              <div style={{width: '300px'}}>
                <Controller
                  name="salaId"
                  control={control}
                  render={({field}) => (
                    <SelectSalas
                      {...field}
                      onChange={(value) => {
                        field.onChange(value);
                        getData({salaId: value});
                      }}
                    />
                  )}
                />
              </div>
            </Col>
            <Col>
              <Button style={{paddingRight: "10px"}} variant={'primary'} onClick={reloadData}
                      icon={<TfiReload/>}/>
            </Col>
          </Row>
        </FlexContainer>
      </PageSection>

      <PageSection>
        <Tabs defaultActiveKey="devices" id="uncontrolled-tab-example" className="mb-3">
          <Tab eventKey="models" title="Modelos">
            {
              loading ?
                <ContainerLoading>
                  <Loading variant='secondary'/>
                </ContainerLoading>
                :
                <Table columns={columns} data={data} emptyListText="No hay datos" pageSize={20} searchable={true}/>
            }
          </Tab>
          <Tab eventKey="cables" title="Cables">
            {
              loading2 ?
                <ContainerLoading>
                  <Loading variant='secondary'/>
                </ContainerLoading>
                :
                <Table columns={columnsConnectors} data={data2} emptyListText="No hay datos" pageSize={20} searchable={true}/>
            }
          </Tab>
        </Tabs>
      </PageSection>
    </>
  );
};

export default ModelBySala;
