import React, {useEffect} from 'react';
import {Controller, useForm} from "react-hook-form";
import PageSection from "../../../../components/PageSection";
import {Col, Row} from "react-bootstrap";
import FormGroup from "../../../../components/FormGroup";
import SelectSalas from "../../../../Selects/SelectSalas";
import Input, {TextArea} from "../../../../components/forms/FormEl";
import DateTimeV2 from "../../../../components/DateTimeV2";

const StageForm = ({value, onSubmit, setAction}) => {

  const {register, handleSubmit, reset,control} = useForm();

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  return (
      <div>
        <PageSection>
          <Row>
            <Col>
              <FormGroup>
                <label style={{fontWeight: 'bold'}}>Sala</label>
                <Controller
                    name="salaId"
                    control={control}
                    render={({field}) => <SelectSalas {...field} />}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <label style={{fontWeight: 'bold'}}>Titulo</label>
                <Input type={'text'} {...register('title')}/>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <FormGroup>
              <label>Fecha y hora estimada</label>
              <Controller name="estimateDate" control={control} render={({field}) => <DateTimeV2 {...field} />}/>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <label>Fecha y hora real</label>
              <Controller name="realDate" control={control} render={({field}) => <DateTimeV2 {...field} />}/>
            </FormGroup>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <label style={{fontWeight: 'bold'}}>Duracion</label>
                <Input type={'number'} {...register('duration')}/>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <label style={{fontWeight: 'bold'}}>Comentario</label>
                <TextArea {...register('comment')}/>
              </FormGroup>
            </Col>
          </Row>
        </PageSection>
      </div>
  );
};

export default StageForm;
